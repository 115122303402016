import { Buffer } from 'buffer'
import { HDKey } from '@scure/bip32'
import { entropyToMnemonic, mnemonicToSeedSync } from '@scure/bip39'
import { wordlist } from '@scure/bip39/wordlists/english'
import { keccak256 } from 'ethereum-cryptography/keccak'
import secp256k1 from 'secp256k1'

export function stripHexPrefix(input: string): string {
  if (input.indexOf('0x') === 0) {
    return input.slice(2)
  }

  return input
}

export function exportMnemonicAndPrivateKey(entropy: Uint8Array, path = 'm/44\'/118\'/0\'/0/0'): {
  mnemonic: string
  privateKey: string | null
  publicKey: string | null
} {
  const mnemonic = entropyToMnemonic(entropy, wordlist)
  const seed = mnemonicToSeedSync(mnemonic)

  const hdkey = HDKey.fromMasterSeed(seed)
  const derivedHdkey = hdkey.derive(path)

  if (!hdkey.privateKey) {
    throw new Error('null hd key')
  }
  const privateKey = byteToHex(derivedHdkey.privateKey)

  const publicKey = secp256k1.publicKeyCreate(hexToByte(privateKey), false)

  return {
    mnemonic,
    privateKey,
    publicKey: byteToHex(publicKey),
  }
}

/**
 * @description Get private information for onboarding using an Ethereum Signature.
 *
 * @returns Mnemonic and Public/Private HD keys
 */
export function deriveHDKeyFromEthereumSignature(signature: string): {
  mnemonic: string
  privateKey: string | null
  publicKey: string | null
} {
  const buffer = Buffer.from(stripHexPrefix(signature), 'hex')

  if (buffer.length !== 65) {
    throw new Error('Signature must be 65 bytes')
  }

  // Remove the 'v' value by taking only the first 64 bytes of the signature
  const rsValues = buffer.subarray(0, 64)
  // Hash the 'r' and 's' values down to 32 bytes (256 bits) using Keccak-256
  const entropy = keccak256(rsValues)
  return exportMnemonicAndPrivateKey(entropy)
}
