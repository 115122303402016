<script setup lang="ts">
const { modal, closeModal } = useModal()
const { getVesselKey } = useKey()
const { invitation } = useUser()

const { address } = useUser()
function continueConnect() {
  getVesselKey()
  invitation.value.code = ''
  closeModal('duplicatedCode')
}
</script>

<template>
  <v-modal
    v-model:show="modal.duplicatedCode"
    modal-class="w-4.88"
    title="Invitation Code Conflict"
  >
    <div class="text-0.16 text-grey1">
      Oops! This wallet address is already linked to another invitation code. Please proceed with wallet log in, or connect your code to a different wallet address.
    </div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button class="flex-1" type="outline" @click="continueConnect">
          Continue with {{ address.slice(-4) }}
        </v-button>
        <v-button class="flex-1" @click="modal.duplicatedCode = false">
          Change Wallet
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
