<script setup lang="ts">
const { modal, closeModal } = useModal()
const router = useRouter()

const { address } = useUser()
const waitlistUrl = import.meta.env.VITE_VESSEL_WAITLIST_URL

function register() {
  router.replace('/login')
  closeModal('walletNotBound')
}
</script>

<template>
  <v-modal
    v-model:show="modal.walletNotBound"
    modal-class="w-4.88"
    title="Wallet Not Registered"
  >
    <div class="text-0.16 text-grey1">
      Oops! {{ shortenAddress(address) }} does not have an invitation code. <br>
      Please get your code to register now or change your wallet address that is already registered!
    </div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button class="flex-1" type="outline" @click="modal.walletNotBound = false">
          Change Wallet
        </v-button>
        <v-button class="flex-1" @click="register">
          Register Now
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
