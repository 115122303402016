<script setup lang="ts">
const { modal, closeModal } = useModal()
const { invitation, clearInvitation } = useUser()
const router = useRouter()

function onConfirm() {
  closeModal('invitationCodeExpired')
}
</script>

<template>
  <v-modal
    v-model:show="modal.invitationCodeExpired" modal-class="w-5"
    type="error"
    title="Invalid Invitation Code"
  >
    <div class="text-0.16 text-grey1">
      The invitation code you used is invalid. Please use the correct code to log in.
    </div>
    <template #footer>
      <div class="mt-0.32 w-full flex justify-between">
        <v-button type="primary" class="w-full" @click="onConfirm">
          OK
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
