<script setup lang="ts">
const { modal, closeModal, openModal } = useModal()

const router = useRouter()

function onStart() {
  router.push('/voyage')
  closeModal('voyage')
}
</script>

<template>
  <v-modal
    v-model:show="modal.voyage"
    modal-class="w-4.48"
    title="Begin Your Voyage"
  >
    <div class="text-0.16 text-grey1">
      Ready to embark on an adventure? Join the Voyage campaign to earn rewards for providing liquidity and inviting friends.
    </div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button class="flex-1" bg="primary" @click="onStart">
          Start Now
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
