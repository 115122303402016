<script setup lang="ts">

</script>

<template>
  <div>
    <ModalConnectWallet />
    <ModalChangeChainID />
    <ModalConnecting />
    <ModalSignVesselKey />
    <ModalInvitationCodeError />
    <ModalInvitationCodeExpired />
    <ModalDeposit />
    <ModalRemindFaucet />
    <ModalDisconnect />
    <ModalWithdraw />
    <ModalClaim />
    <ModalExportPrivateKey />
    <ModalFeedback />
    <ModalWalletNotBound />
    <ModalDuplicatedCode />
    <ModalVoyageGuild />
    <ModalRegisterOnChain />
    <ModalTransfer />
  </div>
</template>

<style scoped>

</style>
