<script setup lang="ts">
import { hexToNumber } from 'viem'

const { closeModal: close, modal, openModal } = useModal()
const { vesselKey } = useKey()
const { isRegistering } = useUser()
const { checkChain } = useConnector()
const notification = useNotification()

const isRegister = ref(false)
function closeModal() {
  close('registerOnChain')
}

function toDeposit() {
  closeModal()
  openModal('deposit')
}

async function getAdminSignature() {
  const res = await vesselApi.user.getAdminSignature()
  const sig = res.data.signature
  return {
    operator: res.data.operator,
    r: sig.slice(0, 66),
    s: `0x${sig.slice(66, 130)}`,
    v: hexToNumber(`0x${sig.slice(130, 132)}`),
  }
}

async function register() {
  const isRightChain = await checkChain()
  if (!isRightChain) {
    return
  }
  isRegister.value = true
  const { v, r, s, operator } = await getAdminSignature()
  const innerPublicKey = vesselKey.value.publicKey
  await writeVault('registerUser', [
    innerPublicKey,
    operator,
    v,
    r,
    s,
  ]).then(() => {
    isRegistering.value = true
    notification.success({
      title: 'Registration Submitted',
      content: 'Your On-Chain registration submitted successfully. Please wait for on-chain and system confirmation, which may take a few minutes.',
      duration: 2000,
    })
    closeModal()
  }).finally(() => {
    isRegister.value = false
  })
}

whenever(computed(() => modal.value.registerOnChain), () => {
  isRegister.value = false
})
</script>

<template>
  <v-modal
    v-model:show="modal.registerOnChain"
    modal-class="w-4.48 text-grey1"
    :z-index="201"
    title="Register On-Chain"
  >
    <div class="text-body2 text-grey1">
      Completing the On-Chain Registration is necessary to start trading on Vessel and to receive internal transfers from others. Registration only needs to be done once.
    </div>
    <div class="mt-0.2 text-body2 text-grey1">
      Vessel will automatically complete the registration when you make your first Deposit.
    </div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button v-if="!isRegister" type="outline" class="flex-1" @click="toDeposit">
          Deposit
        </v-button>
        <v-button class="flex-1" :loading="isRegister" @click="register">
          Register
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
