import type { Router } from 'vue-router'

interface InvitationCode {
  isValid: boolean
  code: string
  isNewCode: boolean
  boundAddress: string
}

const defaultInvitation = {
  isValid: false,
  code: '',
  isNewCode: false,
  boundAddress: '',
}

export function routeGuard(router: Router) {
  const invitation = useStorage<InvitationCode>('invitationCode', defaultInvitation)
  router.beforeEach((to, from, next) => {
    const isValid = invitation.value.isValid

    if ((!isValid) && to.path !== '/login') {
      next('/login')
    }
    else
      if (to.path === '/faucet' && IS_MAINNET) {
        next('/trade')
      }
      else if (to.path === '/') {
        next('/trade')
      }
      else {
        next()
      }
  })
}
