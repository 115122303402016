<script setup>
import { darkTheme } from 'naive-ui'

useResponsive()
useMaintenance()
useReown()
</script>

<template>
  <main font-poppins h-full min-h-full bg-black1 text-0.16 text-white2>
    <n-config-provider :theme="darkTheme" :theme-overrides="themeOverrides" abstract inline-theme-disabled>
      <n-message-provider>
        <n-notification-provider>
          <RouterView />
          <ModalVessel />
        </n-notification-provider>
      </n-message-provider>
    </n-config-provider>
  </main>
</template>
