<script setup lang="ts">

</script>

<template>
  <div class="flex items-start gap-x-0.04 rd-0.08 bg-#37aaec/10 px-0.12 py-0.08">
    <svg class="mt-0.02 flex-none" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path d="M7.00033 10.6667C7.1656 10.6667 7.30424 10.6107 7.41624 10.4987C7.52824 10.3867 7.58405 10.2483 7.58366 10.0834V7.7355C7.58366 7.57022 7.52766 7.43411 7.41566 7.32716C7.30366 7.22022 7.16521 7.16675 7.00033 7.16675C6.83505 7.16675 6.69641 7.22275 6.58441 7.33475C6.47241 7.44675 6.4166 7.58519 6.41699 7.75008V10.098C6.41699 10.2633 6.47299 10.3994 6.58499 10.5063C6.69699 10.6133 6.83544 10.6667 7.00033 10.6667ZM7.00033 6.00008C7.1656 6.00008 7.30424 5.94408 7.41624 5.83208C7.52824 5.72008 7.58405 5.58164 7.58366 5.41675C7.58366 5.25147 7.52766 5.11283 7.41566 5.00083C7.30366 4.88883 7.16521 4.83303 7.00033 4.83341C6.83505 4.83341 6.69641 4.88941 6.58441 5.00141C6.47241 5.11341 6.4166 5.25186 6.41699 5.41675C6.41699 5.58203 6.47299 5.72066 6.58499 5.83266C6.69699 5.94466 6.83544 6.00047 7.00033 6.00008ZM7.00033 13.5834C6.19338 13.5834 5.43505 13.4302 4.72533 13.1237C4.0156 12.8173 3.39824 12.4018 2.87324 11.8772C2.34824 11.3522 1.93271 10.7348 1.62666 10.0251C1.3206 9.31536 1.16738 8.55703 1.16699 7.75008C1.16699 6.94314 1.32021 6.1848 1.62666 5.47508C1.9331 4.76536 2.34863 4.148 2.87324 3.623C3.39824 3.098 4.0156 2.68247 4.72533 2.37641C5.43505 2.07036 6.19338 1.91714 7.00033 1.91675C7.80727 1.91675 8.5656 2.06997 9.27532 2.37641C9.98505 2.68286 10.6024 3.09839 11.1274 3.623C11.6524 4.148 12.0681 4.76536 12.3746 5.47508C12.681 6.1848 12.834 6.94314 12.8337 7.75008C12.8337 8.55703 12.6804 9.31536 12.374 10.0251C12.0675 10.7348 11.652 11.3522 11.1274 11.8772C10.6024 12.4022 9.98505 12.8179 9.27532 13.1243C8.5656 13.4308 7.80727 13.5838 7.00033 13.5834Z" fill="#37AAEC" />
    </svg>
    <div class="flex-1 text-caption2m text-primary">
      <slot />
    </div>
  </div>
</template>
