const gtag: string = import.meta.env.VITE_GTAG_CODE

export function addAnalyze() {
  const script1 = document.createElement('script')
  script1.async = true
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${gtag}`
  const script2 = document.createElement('script')
  script2.innerHTML = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${gtag}');
  `
  document.body.appendChild(script1)
  document.body.appendChild(script2)
}
