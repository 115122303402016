<script setup lang="ts">
import { useDisconnect } from '@wagmi/vue'

const { modal, closeModal } = useModal()
const { disconnectAsync } = useDisconnect()
const { clearKey } = useKey()
const { address } = useUser()

function onDisconnect() {
  console.log(address.value)
  clearKey(address.value)
  disconnectAsync().then(() => {
    disconnectWallet()
    closeModal('disconnect')
  })
}
</script>

<template>
  <v-modal
    v-model:show="modal.disconnect"
    modal-class="w-4.48"
    title="Disconnect"
  >
    <div class="text-0.16 text-grey1">
      Are you sure you want to disconnect your account?
    </div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button class="flex-1" type="outline" @click="closeModal('disconnect')">
          Cancel
        </v-button>
        <v-button class="flex-1" bg="red" @click="onDisconnect">
          Disconnect
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
