import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router/auto'
import App from './App.vue'
import { installSentry } from './lib/sentry'
import { installWagmi } from './lib/wagmi'
import { addAnalyze } from './lib/analyze'
import { useComponents } from '~/components'

import '@unocss/reset/tailwind.css'
import './styles/main.css'
import 'uno.css'

async function main() {
  await getChainConfig()
  const app = createApp(App)
  installSentry(app)
  installWagmi(app)
  addAnalyze()

  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
  })
  routeGuard(router)
  app.use(router)
  app.use(useComponents)
  app.mount('#app')
}

main()
