<script setup lang="ts">
const { modal, closeModal } = useModal()
const router = useRouter()
function toFaucet() {
  router.push('/faucet')
  closeModal('remindFaucet')
}
</script>

<template>
  <v-modal
    v-model:show="modal.remindFaucet"
    modal-class="w-4.48 text-grey1"
    title="Claim Test Tokens"
  >
    <div>
      Welcome!
    </div>
    <div>
      Before you deposit, be sure to visit our Token Faucet to mint your daily test tokens. They’re ideal for a risk-free exploration of our platform. Enjoy your testing!
    </div>
    <template #footer>
      <v-button class="mt-0.32" @click="toFaucet">
        Claim Test Tokens
      </v-button>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
