<script setup lang="ts">
import { useConnect, useDisconnect } from '@wagmi/vue'
import { useAppKit } from '@reown/appkit/vue'
import metamaskImg from '~/assets/image/wallet/metamask.png'
import walletConnectImg from '~/assets/image/wallet/walletConnect.png'
import coinbaseImg from '~/assets/image/wallet/coinbase.png'
import trustImg from '~/assets/image/wallet/trust.png'
import rainbowImg from '~/assets/image/wallet/rainbow.png'
import okxImg from '~/assets/image/wallet/okx.png'
import rabbyImg from '~/assets/image/wallet/rabby.png'
import particleImg from '~/assets/image/wallet/particle.png'
import type { WalletConnectType } from '@/store/connector'

const isShow = defineModel<boolean>('isShow', {
  type: Boolean,
  required: true,
})

const { modal, openModal, closeModal } = useModal()
const { isConnected, currentChainId, lastConnectWalletType } = useConnector()
const message = useMessage()
const { disconnectAsync } = useDisconnect()
const { connectAsync, connectors } = useConnect()

const reownModal = useAppKit()

interface WalletList {
  name: string
  icon: string
  walletType?: WalletConnectType
  disabled?: boolean
}

const walletList: WalletList[] = [
  {
    name: 'Coinbase',
    icon: coinbaseImg,
    walletType: 'particle_coinbase',
  },
  {
    name: 'Trust Wallet',
    icon: trustImg,
    walletType: 'particle_trust',
  },
  // {
  //   name: 'Phantom',
  //   icon: phantomImg,
  //   walletType: 'particle_phantom',
  // },
  {
    name: 'Metamask',
    icon: metamaskImg,
    walletType: 'particle_metamask',
  },
  {
    name: 'OKX Wallet',
    icon: okxImg,
    walletType: 'particle_okx',
  },

]

async function connect(wallet?: WalletList) {
  if (wallet.disabled) {
    return
  }
  try {
    if (!isConnected.value) {
      const connector = connectors.find(i => i.id === wallet.walletType)
      closeModal('chooseProvider')
      lastConnectWalletType.value = wallet.walletType
      connectAsync({
        connector,
        chainId: currentChainId.value,
      }).then(() => {
        closeModal('chooseProvider')
      }).catch((error) => {
        openModal('chooseProvider')
        disconnectAsync().then(() => {
          console.log(error.message)
        })
      }).finally(() => {
        closeModal('connecting')
        isShow.value = false
      })
    }
  }
  catch (e) {

  }
}
</script>

<template>
  <v-modal v-model:show="isShow" title="">
    <template #title>
      <div class="ml-1.35 w-full text-center text-body2b text-white2">
        Login with Particle
      </div>
    </template>
    <div class="mx-auto origin-c scale-120">
      <div class="flex items-center">
        <svg-logo-image mr-0.08 />
        <svg-logo-title />
      </div>
    </div>
    <div class="wallet-list mt-0.32 gap-0.16">
      <div
        v-for="item in walletList" :key="item.name"
        class="h-0.48 w-4 flex items-center gap-x-0.08 rd-0.08 px-0.16 transition-all"
        :class="{
          'hover:bg-primary cursor-pointer bg-black3': !item.disabled,
          'wallet-disabled before:inset-0 before:bg-#31333A/30': item.disabled,
        }"
        @click="connect(item)"
      >
        <img :src="item.icon" alt="" class="h-0.24 w-0.24">
        <div
          class="text-0.14 font-700 font-dm" :class="{
            'text-white2': !item.disabled,
            'text-grey1': item.disabled,
          }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </v-modal>
</template>

<style scoped lang="scss">
.wallet-list {
  display: grid;
  grid-template-columns: 1fr;
}
.wallet-disabled {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;

  }
}
</style>
