import { createAppKit } from '@reown/appkit/vue'

import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import type { CreateConnectorFn } from 'wagmi'
import { http } from 'wagmi'
import { injected, walletConnect } from 'wagmi/connectors'

export function useReown() {
  const projectId = WALLET_CONNECT_PROJECT_ID
  const metadata = {
    name: 'AppKit',
    description: 'AppKit Example',
    url: 'https://trade.vessel.finance', // origin must match your domain & subdomain
    icons: ['https://avatars.githubusercontent.com/u/179229932'],
  }

  const networks: any = getConfigChains()
  const chainConfig = getChainConfigSync()

  const connectors: CreateConnectorFn[] = []
  connectors.push(walletConnect({ projectId, metadata, showQrModal: false })) // showQrModal must be false
  connectors.push(injected({ shimDisconnect: true }))

  // 3. Create Wagmi Adapter
  const wagmiAdapter = new WagmiAdapter({
    transports: chainConfig.reduce((res, i) => ({ ...res, [i.id]: http() }), {}),
    connectors,
    projectId,
    networks,
  })

  // 4. Create modal
  createAppKit({
    adapters: [wagmiAdapter],
    networks,
    metadata,
    projectId,
    features: {
      email: false,
      socials: false,
      emailShowWallets: false,
      analytics: false, // Optional - defaults to your Cloud configuration
    },
  })
}
