<script setup lang="ts">
const { modal } = useModal()
</script>

<template>
  <v-modal
    v-model:show="modal.connecting"
    modal-class="w-4.48"
    title="Connecting"
  >
    <div class="text-0.16 text-grey1">
      Please check your wallet for a connection request.
    </div>
    <template #footer>
      <div class="mt-0.32 flex">
        <v-button class="flex-1" :loading="true">
          Connecting
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
