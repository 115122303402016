<script setup lang="ts">
const { modal, closeModal } = useModal()
const { invitation, clearInvitation } = useUser()
const router = useRouter()

function returnToLogin() {
  clearInvitation()
  router.replace('/login')
  closeModal('invitationCodeBound')
}
</script>

<template>
  <v-modal
    v-model:show="modal.invitationCodeBound" modal-class="w-4.48"
    type="error"
    title="Connection Failed"
  >
    <div class="text-0.16 text-grey1">
      Oops! This invitation code seems to be linked already to another wallet{{ invitation.boundAddress ? ` ending in ${shortenAddress(invitation.boundAddress)}` : '' }}. Please double-check your code and ensure you're connecting with the correct wallet.
    </div>
    <template #footer>
      <div class="mt-0.32 w-full flex justify-between">
        <v-button type="outline" class="w-1.84" @click="returnToLogin">
          Return to Login
        </v-button>
        <v-button class="w-1.84" @click="closeModal('invitationCodeBound')">
          Change Wallet
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
