<script setup lang="ts">
const { modal, closeModal } = useModal()
const { vesselKey } = useKey()
const { copy } = useClipboard({ source: '' })
const notification = useNotification()

function handleCopy() {
  copy(vesselKey.value.privateKey).then(() => {
    notification.success({
      title: 'Key Copied',
      duration: 2000,
      content: 'Your Vessel key has been securely copied. Remember, confidentiality is paramount.',
    })
    closeModal('exportPrivateKey')
  })
}
</script>

<template>
  <v-modal
    v-model:show="modal.exportPrivateKey"
    modal-class="w-4.48"
    title="Export Private Key"
  >
    <div class="text-0.16 text-grey1">
      Keep your Vessel Key private to prevent unauthorized access and asset losses.
    </div>
    <div class="mt-0.32 rd-0.12 bg-#18191D p-0.2 text-0.14 font-700 font-dm">
      <div class="text-white2">
        Vessel Private Key
      </div>
      <div class="text-grey1">
        {{ vesselKey.privateKey?.slice(0, 32) }}...
      </div>
    </div>
    <template #footer>
      <div class="mt-0.32 flex gap-x-0.16">
        <v-button class="flex-1" @click="handleCopy">
          Copy
          <template #prefix>
            <svg-copy fill="#FCFCFD" class="mr-0.12" />
          </template>
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
